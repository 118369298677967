import PropTypes from 'prop-types';
import firebase from 'firebase/app';
import { useRef, useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Icon } from '@iconify/react';
import bellFill from '@iconify/icons-eva/bell-fill';
import clockFill from '@iconify/icons-eva/clock-fill';
// material
import { alpha } from '@mui/material/styles';
import { Box, List, Badge, Divider, Typography, ListItemText, ListSubheader, ListItemButton } from '@mui/material';
// utils
import useAuth from '../../hooks/useAuth';
import { fToNow } from '../../utils/formatTime';
// components
import Scrollbar from '../../components/Scrollbar';
import MenuPopover from '../../components/MenuPopover';
import { MIconButton } from '../../components/@material-extend';

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  notification: PropTypes.object.isRequired,
  onClose: PropTypes.func
};

function NotificationItem({ notification, onClose }) {
  const { action, songId, mixId } = notification;
  const songInfo = `${notification.songname} by ${notification.artist}`;
  const peopleNames = Object.values(notification.people).sort((a, b) => b.latestTime - a.latestTime);

  let whoNotifiedMe;
  if (peopleNames.length > 2) {
    whoNotifiedMe = `${peopleNames[0].name}, ${peopleNames[1].name} and ${peopleNames.length - 2} other${
      peopleNames.length > 3 ? 's' : ''
    }`;
  } else if (peopleNames.length === 2) {
    whoNotifiedMe = `${peopleNames[0].name}, and ${peopleNames[1].name}`;
  } else {
    whoNotifiedMe = peopleNames[0].name;
  }

  let message;
  switch (action) {
    case 'like':
      message = `${whoNotifiedMe} liked a mixx of ${songInfo}`;
      break;
    case 'comment':
      message = `${whoNotifiedMe} commented on a mixx of ${songInfo}`;
      break;
    case 'publish':
      message = `${whoNotifiedMe} published a mixx of ${songInfo}`;
      break;
    default:
      break; // ?
  }

  const url = firebase.storage().publicStorageUrl(`/song/${songId}/mix/${mixId}/landscape_thumbnail.jpg`);

  return (
    <ListItemButton
      to={`/song/${songId}/mixx/${mixId}`}
      component={RouterLink}
      onClick={onClose}
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(notification.isUnRead && {
          bgcolor: 'action.selected'
        })
      }}
    >
      <img
        style={{ marginRight: 15, borderRadius: 3, width: 50, minWidth: 50, minHeight: 42, height: 42 }}
        src={url}
        alt="Thumbnail for song"
      />
      <ListItemText
        primary={message}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled'
            }}
          >
            <Box component={Icon} icon={clockFill} sx={{ mr: 0.5, width: 16, height: 16 }} />
            {fToNow(notification.latestTime)}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

export default function NotificationsPopover() {
  const { user } = useAuth();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [highWatermark, setHighWatermark] = useState(0);
  const [notifications, setNotifications] = useState([]);

  const firestore = firebase.firestore();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);

    firestore.collection('user_notifications').doc(user.id).update({
      highWatermark: notifications[0].latestTime
    });
  };

  useEffect(() => {
    if (user.id) {
      return firestore
        .collection('user_notifications')
        .doc(user.id)
        .onSnapshot((doc) => {
          const newNotifications = {};
          const data = doc.data() || {};

          setHighWatermark(data.highWatermark || 0);

          const keys = Object.keys(data).filter((d) => d !== 'highWatermark');
          keys.forEach((key) => {
            const notification = data[key];
            const notificationKey = `${notification.mixId}_${notification.action}`;
            newNotifications[notificationKey] = newNotifications[notificationKey] || {
              id: notificationKey,
              latestTime: 1,
              songname: notification.songname,
              artist: notification.artist,
              mixId: notification.mixId,
              songId: notification.songId,
              action: notification.action,
              people: {},
              keys: []
            };
            newNotifications[notificationKey].keys.push(key);
            newNotifications[notificationKey].latestTime = Math.max(
              newNotifications[notificationKey].latestTime,
              notification.created.toDate().getTime()
            );

            newNotifications[notificationKey].people[notification.userId] = { name: notification.name, latestTime: 1 };
            newNotifications[notificationKey].people[notification.userId].latestTime = Math.max(
              newNotifications[notificationKey].people[notification.userId].latestTime,
              notification.created.toDate().getTime()
            );
          });

          let sortedNotifications = Object.values(newNotifications).sort((a, b) => b.latestTime - a.latestTime);
          let notificationsToRemove = [];
          if (sortedNotifications.length > 50) {
            sortedNotifications.slice(40).forEach((notification) => {
              notificationsToRemove = notificationsToRemove.concat(notification.keys);
            });
            sortedNotifications = sortedNotifications.slice(0, 40);
          }
          if (keys.length > 50) {
            const sortedKeys = keys.sort(
              (a, b) => data[a].created.toDate().getTime() - data[b].created.toDate().getTime()
            );
            notificationsToRemove = notificationsToRemove.concat(sortedKeys.slice(0, 40));
          }

          if (notificationsToRemove.length > 0) {
            firestore
              .collection('user_notifications')
              .doc(user.id)
              .update(
                notificationsToRemove.reduce((acc, key) => {
                  acc[key] = firebase.firestore.FieldValue.delete();
                  return acc;
                }, {})
              );
          }
          setNotifications(sortedNotifications);
        });
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.id]);

  const freshNotifications = notifications.filter((a) => a.latestTime > highWatermark);
  const staleNotifications = notifications.filter((a) => a.latestTime <= highWatermark);

  return (
    <>
      <MIconButton
        ref={anchorRef}
        size="large"
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        sx={{
          ...(open && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity)
          })
        }}
      >
        <Badge badgeContent={freshNotifications.length} color="error">
          <Icon icon={bellFill} width={20} height={20} />
        </Badge>
      </MIconButton>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{ width: 360 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
          </Box>
        </Box>

        <Divider />

        <Scrollbar sx={{ maxHeight: 340 }}>
          {!!freshNotifications.length && (
            <List
              disablePadding
              subheader={
                <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                  New
                </ListSubheader>
              }
            >
              {freshNotifications.map((notification) => (
                <NotificationItem onClose={handleClose} key={notification.id} notification={notification} />
              ))}
            </List>
          )}

          {!!staleNotifications.length && (
            <List
              disablePadding
              subheader={
                <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                  Before that
                </ListSubheader>
              }
            >
              {staleNotifications.map((notification) => (
                <NotificationItem onClose={handleClose} key={notification.id} notification={notification} />
              ))}
            </List>
          )}
        </Scrollbar>
      </MenuPopover>
    </>
  );
}
