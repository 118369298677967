import { styled } from '@mui/material/styles';
import { Box, Card, Stack, Container, Typography, Button } from '@mui/material';
// hooks
import useAuth from '../../hooks/useAuth';
// layouts
import AuthLayout from '../../layouts/AuthLayout';
// components
import Page from '../../components/Page';
import { MHidden } from '../../components/@material-extend';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Login() {
  const { logout } = useAuth();

  // const handleLoginAuth0 = async () => {
  //   try {
  //     await login();
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  return (
    <RootStyle title="Login | mixxlab.io">
      <AuthLayout />

      <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            Thanks for your interest!
          </Typography>
          <img style={{ transform: 'scaleX(-1)' }} src="/static/illustrations/illustration_login.png" alt="login" />
        </SectionStyle>
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="h4" gutterBottom>
                You've successfully created an account! Unfortunately mixxlab.io is only available for alpha testers.
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>Contact Josh to become an alpha tester!</Typography>
            </Box>
            <Button variant="contained" onClick={() => logout()}>
              Logout
            </Button>
            {/* 
            <Tooltip title={capitalCase(method)}>
              <Box component="img" src={`/static/auth/ic_${method}.png`} sx={{ width: 32, height: 32 }} />
            </Tooltip> */}
          </Stack>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
