// ----------------------------------------------------------------------

export default function Slider(theme) {
  const isLight = theme.palette.mode === 'light';

  return {
    MuiSlider: {
      defaultProps: {
        size: 'small'
      },

      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: theme.palette.action.disabled
          },
          '.MuiSlider-thumb': {
            transition: 'left linear 250ms'
          },
          '.MuiSlider-track': {
            transition: 'width linear 250ms'
          }
        },
        markLabel: {
          fontSize: 13,
          color: theme.palette.text.disabled
        },
        valueLabel: {
          borderRadius: 8,
          backgroundColor: theme.palette.grey[isLight ? 800 : 700]
        }
      }
    }
  };
}
