import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';

// routes
import { PATH_SONG, PATH_EXPLORE, PATH_PAGE } from '../../routes/paths';
// components
import SvgIconStyle from '../../components/SvgIconStyle';
// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  booking: getIcon('ic_booking'),
  create: <VideoCameraFrontIcon />
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'general',
  //   items: [
  //     {
  //       title: 'app',
  //       path: PATH_DASHBOARD.general.app,
  //       icon: ICONS.dashboard
  //     },
  //     { title: 'e-commerce', path: PATH_DASHBOARD.general.ecommerce, icon: ICONS.ecommerce },
  //     { title: 'analytics', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics },
  //     { title: 'banking', path: PATH_DASHBOARD.general.banking, icon: ICONS.banking },
  //     { title: 'booking', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking }
  //   ]
  // },

  {
    subheader: 'mixxes',
    items: [
      {
        title: 'feed',
        path: PATH_EXPLORE.explore.feed,
        icon: ICONS.analytics
      },
      {
        title: 'following',
        path: PATH_EXPLORE.explore.following,
        icon: ICONS.analytics
      },
      {
        title: 'latest',
        path: PATH_EXPLORE.explore.latest,
        icon: ICONS.booking
      },
      {
        title: 'weekly hotness',
        path: PATH_EXPLORE.explore.weekly_hotness,
        icon: ICONS.calendar
      },
      {
        title: 'monthly hotness',
        path: PATH_EXPLORE.explore.monthly_hotness,
        icon: ICONS.calendar
      },
      {
        title: 'all time hotness',
        path: PATH_EXPLORE.explore.all_time_hotness,
        icon: ICONS.calendar
      },
      {
        title: 'create',
        path: PATH_SONG.song.create,
        icon: ICONS.create
      }
    ]
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'management',
  //   items: [
  //     // MANAGEMENT : USER
  //     {
  //       title: 'user',
  //       // path: PATH_USER.user.root,
  //       icon: ICONS.user,
  //       children: [
  //         { title: 'profile', path: PATH_USER.user.profile },
  //         { title: 'edit', path: PATH_USER.user.edit_profile }
  //       ]
  //     }

  //     // MANAGEMENT : E-COMMERCE
  //     // {
  //     //   title: 'e-commerce',
  //     //   path: PATH_DASHBOARD.eCommerce.root,
  //     //   icon: ICONS.cart,
  //     //   children: [
  //     //     { title: 'shop', path: PATH_DASHBOARD.eCommerce.shop },
  //     //     { title: 'product', path: PATH_DASHBOARD.eCommerce.productById },
  //     //     { title: 'list', path: PATH_DASHBOARD.eCommerce.list },
  //     //     { title: 'create', path: PATH_DASHBOARD.eCommerce.newProduct },
  //     //     { title: 'edit', path: PATH_DASHBOARD.eCommerce.editById },
  //     //     { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout },
  //     //     { title: 'invoice', path: PATH_DASHBOARD.eCommerce.invoice }
  //     //   ]
  //     // },

  //     // // MANAGEMENT : BLOG
  //     // {
  //     //   title: 'blog',
  //     //   path: PATH_DASHBOARD.blog.root,
  //     //   icon: ICONS.blog,
  //     //   children: [
  //     //     { title: 'posts', path: PATH_DASHBOARD.blog.posts },
  //     //     { title: 'post', path: PATH_DASHBOARD.blog.postById },
  //     //     { title: 'new post', path: PATH_DASHBOARD.blog.newPost }
  //     //   ]
  //     // }
  //   ]
  // }

  // APP
  // ----------------------------------------------------------------------
  {
    subheader: 'help',
    items: [{ title: 'FAQ', path: PATH_PAGE.faqs, icon: ICONS.chat }]
  }
];

export default sidebarConfig;
