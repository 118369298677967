import { useState } from 'react';
import mobile from 'is-mobile';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { Button, DialogContentText, DialogContent } from '@mui/material';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
// hooks
import useAuth from '../hooks/useAuth';
// pages
import Login from '../pages/authentication/Login';
import AlphaOptIn from '../pages/authentication/AlphaOptIn';

import LoadingScreen from '../components/LoadingScreen';
// ----------------------------------------------------------------------

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default function AuthGuard({ children }) {
  const { isAuthenticated, user } = useAuth();
  const [warningClosed, setWarningClosed] = useState(localStorage.warningClosed);
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  if (!user) {
    return <LoadingScreen />;
  }

  // if (!user.alpha && user.hasLoadedProfile) {
  //   return <AlphaOptIn />;
  // }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  let warningDialog = null;
  if (mobile()) {
    warningDialog = (
      <Dialog open scroll="paper">
        <DialogTitle id="alert-dialog-title">Mobile Warning</DialogTitle>
        <DialogContent sx={{ width: 300 }}>
          <DialogContentText sx={{ paddingTop: 2 }} id="alert-dialog-description">
            You are using this site on a mobile device. Viewing, liking and commenting on mixxes should probably work
            okay. Remixxing existing clips should also probably work.
          </DialogContentText>
          <DialogContentText sx={{ paddingTop: 2 }} id="alert-dialog-description">
            Recording new clips on bluetooth or an audio interface probably will not work. We've only had success using
            wired earphones and the phone's microphone.
          </DialogContentText>
          <DialogContentText sx={{ paddingTop: 2 }} id="alert-dialog-description">
            Good luck!
          </DialogContentText>
          <Button
            sx={{ paddingTop: 2 }}
            onClick={() => {
              localStorage.warningClosed = true;
              setWarningClosed(true);
            }}
          >
            I understand
          </Button>
        </DialogContent>
      </Dialog>
    );
  } else if (!(navigator?.userAgentData?.brands?.findIndex((d) => d.brand.indexOf('Chrom') >= 0) >= 0)) {
    warningDialog = (
      <Dialog open scroll="paper">
        <DialogTitle id="alert-dialog-title">Browser Warning</DialogTitle>
        <DialogContent sx={{ width: 300 }}>
          <DialogContentText sx={{ paddingTop: 2 }} id="alert-dialog-description">
            You're not using Google Chrome.
          </DialogContentText>
          <DialogContentText sx={{ paddingTop: 2 }} id="alert-dialog-description">
            Recording new clips on bluetooth or an audio interface probably will not work. We've only had success using
            wired earphones and the phone's microphone.
          </DialogContentText>
          <DialogContentText sx={{ paddingTop: 2 }} id="alert-dialog-description">
            Good luck!
          </DialogContentText>
          <Button
            sx={{ paddingTop: 2 }}
            onClick={() => {
              localStorage.warningClosed = true;
              setWarningClosed(true);
            }}
          >
            I understand
          </Button>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <>
      {children}
      {!warningClosed ? warningDialog : null}
    </>
  );
}
