import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';
import { firebaseConfig } from '../config';

// ----------------------------------------------------------------------

// const ADMIN_EMAILS = ['demo@minimals.cc'];

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);

  let storageBaseUrl = 'https://firebasestorage.googleapis.com';

  if (process.env.REACT_APP_FIREBASE_EMULATORS) {
    const firebaseEmulators = {
      functions: {
        host: 'localhost',
        port: 5001
      },
      database: {
        host: 'localhost',
        port: 9000
      },
      firestore: {
        host: 'localhost',
        port: 8080
      },
      storage: {
        host: 'localhost',
        port: 9199
      }
    };

    if (firebaseEmulators) {
      console.log('Automatically connecting Firebase SDKs to running emulators:');
      // Object.keys(firebaseEmulators).forEach((key) => {
      //   // console.log('\t' + key + ': http://' +  firebaseEmulators[key].host + ':' + firebaseEmulators[key].port );
      // });

      if (firebaseEmulators.database && typeof firebase.database === 'function') {
        firebase.database().useEmulator(firebaseEmulators.database.host, firebaseEmulators.database.port);
      }

      if (firebaseEmulators.firestore && typeof firebase.firestore === 'function') {
        firebase.firestore().useEmulator(firebaseEmulators.firestore.host, firebaseEmulators.firestore.port);
      }

      if (firebaseEmulators.functions && typeof firebase.functions === 'function') {
        firebase.functions().useEmulator(firebaseEmulators.functions.host, firebaseEmulators.functions.port);
      }

      if (firebaseEmulators.storage && typeof firebase.storage === 'function') {
        storageBaseUrl = `http://${firebaseEmulators.storage.host}:${firebaseEmulators.storage.port}`;
        firebase.storage().useEmulator(firebaseEmulators.storage.host, firebaseEmulators.storage.port);
      }

      if (firebaseEmulators.auth && typeof firebase.auth === 'function') {
        firebase.auth().useEmulator(`http://${firebaseEmulators.auth.host}:${firebaseEmulators.auth.port}`);
      }
    }
  }

  firebase.storage().publicStorageUrl = (path, cb) => {
    const p = `/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/o/${encodeURIComponent(path.slice(1))}?alt=media${
      cb ? `&${cb}` : ''
    }`;

    if (!process.env.REACT_APP_FIREBASE_EMULATORS) {
      return `https://costarlab.b-cdn.net${p}`;
    }

    return `${storageBaseUrl}/v0/b${p}`;
  };

  firebase.firestore();
}

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const reducer = (state, action) => {
  if (action.type === 'INITIALISE') {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  }

  return state;
};

const AuthContext = createContext({
  ...initialState,
  method: 'firebase',
  login: () => Promise.resolve(),
  register: () => Promise.resolve(),
  loginWithGoogle: () => Promise.resolve(),
  loginWithFaceBook: () => Promise.resolve(),
  loginWithTwitter: () => Promise.resolve(),
  logout: () => Promise.resolve()
});

AuthProvider.propTypes = {
  children: PropTypes.node
};

function AuthProvider({ children }) {
  const [profile, setProfile] = useState(null);
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(
    () =>
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          // This is an example script - don't forget to change it!
          if (window.FS) {
            window.FS.identify(user.uid, {
              displayName: user.displayName,
              email: user.email
            });
          }

          const actions = firebase.functions().httpsCallable('actions');
          actions({
            action: 'fromAuth',
            params: Object.keys(user).reduce((acc, key) => {
              if (typeof user[key] === 'string') {
                acc[key] = user[key];
              }
              return acc;
            }, {})
          }).then((d) => {
            setProfile({ ...d.data, hasLoadedProfile: true });
          });

          dispatch({
            type: 'INITIALISE',
            payload: { isAuthenticated: true, user }
          });
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: { isAuthenticated: false, user: null }
          });
        }
      }),
    [dispatch]
  );

  const login = (email, password) => firebase.auth().signInWithEmailAndPassword(email, password);

  const loginWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    return firebase.auth().signInWithPopup(provider);
  };

  const loginWithFaceBook = () => {
    const provider = new firebase.auth.FacebookAuthProvider();
    return firebase.auth().signInWithPopup(provider);
  };

  const loginWithTwitter = () => {
    const provider = new firebase.auth.TwitterAuthProvider();
    return firebase.auth().signInWithPopup(provider);
  };

  const register = (email, password, firstName, lastName) =>
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((res) => {
        firebase
          .firestore()
          .collection('users')
          .doc(res.user.uid)
          .set({
            uid: res.user.uid,
            email,
            displayName: `${firstName} ${lastName}`
          });
      });

  const logout = async () => {
    await firebase.auth().signOut();
  };

  const resetPassword = async (email) => {
    await firebase.auth().sendPasswordResetEmail(email);
  };

  const auth = { ...state.user };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'firebase',
        user: {
          ...auth,
          ...profile,
          id: auth.uid,
          displayName: profile?.displayName || auth.displayName,
          photoURL: auth.photoURL || profile?.photoURL
        },
        login,
        register,
        loginWithGoogle,
        loginWithFaceBook,
        loginWithTwitter,
        logout,
        resetPassword
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
