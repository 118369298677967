// hooks
import firebase from 'firebase/app';
import useAuth from '../hooks/useAuth';
//
import { MAvatar } from './@material-extend';
import createAvatar from '../utils/createAvatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }) {
  const { user } = useAuth();
  const url = firebase.storage().publicStorageUrl(`/users/${user.id}/profile.jpg`, user.photoVersion || '');

  return (
    <MAvatar
      src={url}
      alt={user.displayName}
      color={user.photoURL ? 'default' : createAvatar(user.displayName).color}
      {...other}
    >
      {createAvatar(user.displayName).name}
    </MAvatar>
  );
}
